<template>
    <custom-vue-table class="pl-3 pr-3" title="Sale Orders" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                      :extra-params="{ start_date, status, end_date }">
        <template #filters>
            <div class="btn-group ml-3">
                <drop-btn text="Filters" class="ml-3" icon="fa fa-filter" size="md" dropdown-width="25r">
                    <div class="p-3">
                        <validated-vue-select name="Status" :options="statusOptions"
                                              label="Status"
                                              v-model="status"/>
                        <validated-input type="date" label="Start Date" name="Start Date"
                                         v-model="start_date"/>
                        <validated-input type="date" label="End Date" name="End Date"
                                         v-model="end_date"/>
                    </div>
                </drop-btn>
            </div>
        </template>
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn color="primary" class="btn-basic-b" size="xs" @click="viewDetails(rowData)" text="View"/>
            </div>
        </template>
    </custom-vue-table>
</template>

<script>
import urls from '../../../../data/urls';

export default {
    name : 'ListingComponent',
    data () {
        return {
            start_date    : '',
            end_date      : '',
            status        : '',
            listUrl       : urls.salesAdmin.orders.list,
            statusOptions : [
                { value : 'Ordered', label : 'Ordered' },
                { value : 'Processing', label : 'Processing' },
                { value : 'Packed', label : 'Packed' },
                { value : 'Completed', label : 'Completed' },
                { value : 'Shipped', label : 'Shipped' },
                { value : 'Delivery Assigned', label : 'Delivery Assigned' },
                { value : 'Delivery Picked', label : 'Delivery Picked' },
                { value : 'Delivered', label : 'Delivered' }
            ],
            fields : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'Order ID'
                },
                {
                    name      : 'sale_date',
                    sortField : 'sale_date',
                    title     : 'Sale Date'
                },
                {
                    name      : 'sub_total',
                    sortField : 'sub_total',
                    title     : 'Sub Total'
                },
                {
                    name      : 'total_amount',
                    sortField : 'total_amount',
                    title     : 'Total'
                },
                {
                    name      : 'status',
                    sortField : 'status',
                    title     : 'Status'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        viewDetails (rowData) {
            this.$router.push('/sales/order/' + rowData.id + '/details/');
        }
    }
};
</script>
